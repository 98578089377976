<template>
  <base-section id="certificacion">
    <v-container>
      <v-row>
        <v-col cols="12">
          <base-section-heading title="Certificación">
          </base-section-heading>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
         <!-- <base-title
            class="red--text"
            title="¿Qué es la certificación ?"
            tag="div"
            style="color: red"
          />-->

          <h2 class="font-weight-bold mb-0 red--text text-left mb-4" >
            ¿Qué es la certificación ?
          </h2>
          <div class="font-weight-bold" style="text-align: justify; color: #636363">
            <p style="text-align: justify" >Es el proceso de evaluación a que voluntariamente se somete un médico después de haber cursado y aprobado un programa de especialización (residencia médica avalada por una institución de enseñanza superior) en alguna rama de la medicina.</p>
            <p style="text-align: justify">Es la garantía a la sociedad de que un médico al terminar su residencia médica, ha sido evaluado como especialista competente en su disciplina y que cumple con el perfil profesional y los estándares de calidad que establecen los programas educativos.</p>
          </div>
        </v-col>
        <v-col cols="12" md="6">
          <!--<base-title
            class="red--text"
            title="¿ Quién puede aplicar a la certificación ?"
            tag="div"
            style="color: red"
          />-->
          <h2 class="font-weight-bold mb-0 red--text text-left mb-4" >
            <!--Consejo Mexicano de Patología Clínica y Medicina de Laboratorio, A. C.-->
            ¿ Quién puede aplicar a la certificación ?

          </h2>

          <div class="font-weight-bold" style="text-align: justify; color: #636363">
            <p style="text-align: justify" >
              Los médicos cirujanos que voluntariamente soliciten el examen de certificación y que cumplan los requisitos de la convocatoria.
            </p>
            <p style="text-align: justify">Los médicos cirujanos especialistas certificados, al término de la vigencia de su diploma de certificación y que cumplan los requisitos de la convocatoria</p>
          </div>


        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionCertificacion',

    data: () => ({

    }),
  }
</script>
